import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useI18n from 'hooks/useI18n'

import { Card, Flex,Text,Button} from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useTokenInfo } from 'hooks/Tokens'
import toPrecision, { toFormatPrice } from 'utils/getPrecision'
import { Token } from '@pancakeswap-libs/sdk-v2'
import { getChecksumAddress } from '../../../utils/addressHelpers'


import RemoveBtn from './RemoveBtn'
import Details from './Details'
import TransferBtn from './TransferBtn'
import AlertHelper from '../../../components/AlertHelper'
import Logo from '../../../components/Logo'






interface ListContentProps {
  item: {
    address:string,
    amounts:Array<any>,
    fee:number,
    prices:Array<any>,
    // form
    stock:string,
    // to
    money:string,
    ids:Array<any>,
    deprecated:boolean
  }
  gridInfos:any
}
interface ChildenTrProps {
  item: {
    address:string,
    amounts:Array<any>,
    fee:number,
    prices:Array<any>,
    // form
    stock:string,
    // to
    money:string,
    ids:Array<any>,
    deprecated:boolean
  },
  t:any,
  idx:number,
  apy:any
}
interface LpDetailsProps {
  tokenA?:Token|null|undefined,
  tokenB?:Token|null|undefined,
  tokenAAmount?:string,
  tokenBAmount?:string,
  dayApy:number,
  weekApy:number,
  yearApy:number
}

const StyleRow20 = styled(Text)<{isMobile:boolean}>`
      display: flex;
      width:20%;
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
`
const StyleRow25 = styled(Text)<{isMobile:boolean}>`
      display: flex;
      width:25%;
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
`
const StyleRow35 = styled(Text)<{isMobile:boolean}>`
      display: flex;
      width:35%;
      align-items: center;
      font-size: ${props => props.isMobile ? "12px" : '16px'};
`
const StyleDown = styled(Text)`
    display: flex;
    align-items: center;
    width:5%;
`
const ChildenTh = styled(Flex)`
   background-color:${({ theme })=>theme.colors.background};
`

const DetailsText = styled(Text)<{isMobile:boolean}>`
   font-size: ${props => props.isMobile ? "12px" : '16px'};
`

const StyleTokenDetails = styled(Flex)<{isMobile:boolean}>`
  border:2px solid ${({ theme })=>theme.colors.input};
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  min-width:${props => props.isMobile ? "auto" : '300px'};;
  background-color:${({ theme })=>theme.colors.background};
`
const TokenImage = styled(Logo)`
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
`


const LpDetails: React.FC<LpDetailsProps>= ({tokenA,tokenB,tokenAAmount,tokenBAmount,dayApy,weekApy,yearApy}) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints();
  return (
    <>
         <Flex>
                <StyleDown/> 
                <Flex p={!isDesktop?2:3} pl={!isDesktop?2:0} 
                 justifyContent='flex-start' 
                 flexDirection={!isDesktop?'column':'row'} 
                 flex={1}>
                  <StyleTokenDetails  isMobile={!isDesktop} flexDirection='column'>
                    <DetailsText bold isMobile={!isDesktop}>{TranslateString(11156,'Liquidity Pool')}</DetailsText>
                    <Flex flex={1} mt={2} p={1} flexDirection='column'>
                      <Flex justifyContent='space-between'>
                        
                        <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenA?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${tokenA?.address} Logo`} />
                       
                        <Flex>
                          <DetailsText isMobile={!isDesktop} >{tokenAAmount}</DetailsText>
                          <DetailsText ml={2} color="#7d7a7a" isMobile={!isDesktop}>{tokenA?.symbol}</DetailsText>
                        </Flex>
                        </Flex>
                      <Flex justifyContent='space-between' mt={2}>
                        <TokenImage srcs={[`https://asset.benswap.cash/assets/${getChecksumAddress(tokenB?.address)}/logo.png`, "/images/UNKNOWNSEP20.png"]} alt={`${tokenB?.address} Logo`} />
                        <Flex>
                          <DetailsText isMobile={!isDesktop} >{tokenBAmount}</DetailsText>
                          <DetailsText ml={2} color="#7d7a7a" isMobile={!isDesktop}>{tokenB?.symbol}</DetailsText>

                        </Flex>
                      </Flex>
                    </Flex>
                  </StyleTokenDetails>

                  <StyleTokenDetails isMobile={!isDesktop} mt={!isDesktop?3:0} ml={!isDesktop?0:4} flexDirection='column'>
                   <DetailsText bold isMobile={!isDesktop}>{TranslateString(11157,'APY')}</DetailsText>
                    <Flex flex={1} mt={2} p={1} flexDirection='column'>
                      <Flex justifyContent='space-between'><DetailsText isMobile={!isDesktop}>{TranslateString(11158,'1-Day APY')}</DetailsText><DetailsText isMobile={!isDesktop}>{parseFloat((dayApy*100).toPrecision(12))}%</DetailsText></Flex>
                      <Flex justifyContent='space-between'><DetailsText isMobile={!isDesktop}>{TranslateString(11159,'7-Day APY')}</DetailsText><DetailsText isMobile={!isDesktop}>{parseFloat((weekApy*100).toPrecision(12))}%</DetailsText></Flex>
                      <Flex justifyContent='space-between'><DetailsText isMobile={!isDesktop}>{TranslateString(11160,'1-Year APY')}</DetailsText><DetailsText isMobile={!isDesktop}>{parseFloat((yearApy*100).toPrecision(12))}%</DetailsText></Flex>
                    </Flex>
                  </StyleTokenDetails>

       
                </Flex>
              </Flex>
    </>
  )
}

const ChildenTr: React.FC<ChildenTrProps> = ({ item,t,idx,apy}) => {
  const { isDesktop } = useMatchBreakpoints();
  const [status,setStatus]=useState(false);
  const tokenFrom=useTokenInfo(item.stock);
  const tokenTo=useTokenInfo(item.money);
  const tokenAmount=(amount:string,decimals?:number)=>{
    const res=new BigNumber(amount).div(new BigNumber(10).pow(decimals||0))
    return toFormatPrice(res,2,2)
  }
  const getAmount=(amount:string)=>{
    const res= new BigNumber(amount).div(new BigNumber(10).pow(tokenFrom?.decimals||0))
    return toPrecision(res,2)
  }
  
  const tranApy=useMemo(()=>{
    return {
      dayApy: new BigNumber(toPrecision(new BigNumber(apy?.dayAPY),2)).toNumber(),
      weekDayApy:new BigNumber(toPrecision(new BigNumber(apy?.weekAPY),2)).toNumber(),
      yearApy:new BigNumber(toPrecision(new BigNumber(apy?.yearAPY),2)).toNumber(),
    }
  },[apy])


  return (
    <>
            <Flex key={item.stock+t}  flex={1} p={2} flexDirection='column'>
                   <Flex>
                      <StyleDown/>
                      <StyleRow35 isMobile={!isDesktop} fontSize='14px'>
                          {toFormatPrice(new BigNumber(item.prices[idx][0]),3,3)}-{toFormatPrice(new BigNumber(item.prices[idx][1]),3,3)}
                      </StyleRow35>
                      <StyleRow25 isMobile={!isDesktop} fontSize='14px'>{getAmount(t)}</StyleRow25>
                      <StyleRow20 isMobile={!isDesktop}>
                              <RemoveBtn deprecated={item?.deprecated} ids={[item.ids[idx]]} pairAddress={item.address} amount={getAmount(t)} removedAmounts={[t]}/>
                              <Flex ml={2}/>
                              <TransferBtn ids={[item.ids[idx]]} pairAddress={item.address} amount={getAmount(t)} removedAmounts={[t]}/>
                      </StyleRow20>
                      <StyleRow20 onClick={()=>setStatus(!status)} isMobile={!isDesktop}>
                        <Details actionPanelToggled={status}/>
                      </StyleRow20>
                    </Flex>
                
                    {status?
                           <LpDetails 
                           tokenA={tokenFrom}
                           tokenB={tokenTo}
                           tokenAAmount={tokenAmount(apy.userLeftStock,tokenFrom?.decimals)}
                           tokenBAmount={tokenAmount(apy.userGotMoney,tokenTo?.decimals)}
                           dayApy={tranApy.dayApy}
                           weekApy={tranApy.weekDayApy}
                           yearApy={tranApy.yearApy}
                         />:"" }
                </Flex>
    </>
  )
}


const ListContent: React.FC<ListContentProps> = ({ item,gridInfos }) => {
  const TranslateString = useI18n()
  const { isDesktop } = useMatchBreakpoints();
  const [status,setStatus]=useState(false);
  const tokenFrom=useTokenInfo(item.stock);
  const tokenTo=useTokenInfo(item.money);

  const getAmount=(amount:string)=>{
    const res= new BigNumber(amount).div(new BigNumber(10).pow(tokenFrom?.decimals||0))
    return toPrecision(res,2)
  }
  const total=(totals:Array<any>)=>{
    if(totals.length===0){
      return ""
    }
    let sum = new BigNumber(0);
    totals.reduce(function(pre,curr) {
    sum=new BigNumber(pre).plus(new BigNumber(curr));
    return sum;
    });
    const res=sum.div(new BigNumber(10).pow(tokenFrom?.decimals||0))
    return toPrecision(res,2)
  }


  const totalAmount=(totals:Array<any>,decimals?:number)=>{
    if(totals.length===0){
      return ""
    }
    let sum = new BigNumber(0);
    totals.reduce(function(pre,curr) {
    sum=new BigNumber(pre).plus(new BigNumber(curr));
    return sum;
    });
    const res=sum.div(new BigNumber(10).pow(decimals||0))
    console.log(res.toFixed())
    return toPrecision(res,2)
  }
  const gridInfo=useMemo(()=>((gridInfos?.grids||[]).filter(f=>f.address===item.address)),[gridInfos,item])
  const pairs=useMemo(()=>((gridInfos?.pairs||[]).find(f=>f.address===item.address)),[gridInfos,item])

  const apy=useMemo(()=>{
    // const gridInfo:Array<any>=gridInfos.filter(f=>f.address===item.address);
    // const totalStocks=gridInfo.map(t=>t.totalStock);
    // const apys=gridInfo.map(t=>new BigNumber(t.dayAPY).times(new BigNumber(t.totalStock)) );
 
    // const totalStocksNum =totalStocks.reduce(function(pre,curr) {
    //   const sum=new BigNumber(pre).plus(new BigNumber(curr));
    //   return sum;
    // });
    // const apysNum =apys.reduce(function(pre,curr) {
    //   const sum=new BigNumber(pre).plus(new BigNumber(curr));
    //   return sum;
    // });
    // console.log("apysNum",apysNum.div(totalStocksNum).toFixed())
    return {
      leftStocks:gridInfo.map(t=>t.userLeftStock),
      gotMoneys:gridInfo.map(t=>t.userGotMoney),
      dayApy: new BigNumber(toPrecision(new BigNumber(pairs?.dayAPY),2)).toNumber(),
      weekDayApy:new BigNumber(toPrecision(new BigNumber(pairs?.weekAPY),2)).toNumber(),
      yearApy:new BigNumber(toPrecision(new BigNumber(pairs?.yearAPY),2)).toNumber(),
    }
  },[gridInfo,pairs])

  const childen=(itemObj:any)=>(
    <>
       <Flex flexDirection="column" key={itemObj.address}>
               <Flex flex={1} >
                   <StyleDown/>

                   <ChildenTh flex={1} p={2}>
                     <StyleRow35 isMobile={!isDesktop}>{TranslateString(10086,"Price")}</StyleRow35>
                     <StyleRow25 isMobile={!isDesktop}>{TranslateString(11132,"Amount")}</StyleRow25>
                     <StyleRow20 isMobile={!isDesktop}>{TranslateString(11133,"Operation")}</StyleRow20>
                   </ChildenTh>

               </Flex>
               { itemObj.amounts.length>0 && itemObj.amounts.map((t,idx)=>{
                 const itemInfo=gridInfo.find(h=>h.gridId===itemObj.ids[idx]);
                 return <ChildenTr apy={itemInfo} item={item} t={t} idx={idx}/> 
               })
                 
                
       }
        </Flex>
    </> 

  )

  return (
    <>

    <Flex flex={1} p={2}>
                  <StyleDown  onClick={()=>setStatus(!status)}>
                              <Details actionPanelToggled={status}/>
                  </StyleDown>
                  <StyleRow35 style={{width:isDesktop?"40%":""}}  isMobile={!isDesktop}>
                    {isDesktop?<>
                      <div>{tokenFrom?.symbol}-{tokenTo?.symbol}</div>
                      <Flex>
                        {item?.deprecated===true?
                        <>({TranslateString(11153,'deprecated')})<AlertHelper text={TranslateString(11154,"The trade pair has been deprecated, please remove it and add it again")}/></>:
                        <>&nbsp;({toFormatPrice(new BigNumber(item?.prices[0][0]),3,3)}-{toFormatPrice(new BigNumber(item?.prices[item?.prices.length-1][1]),3,3)})</>
                        }
                     </Flex>
                    </>:<Flex  flexDirection="column">
                      <div>{tokenFrom?.symbol}-{tokenTo?.symbol}</div>
                      <Flex>
                       {item?.deprecated===true?
                        <>({TranslateString(11153,'deprecated')})<AlertHelper text={TranslateString(11154,"The trade pair has been deprecated, please remove it and add it again")}/></>:
                        <>&nbsp;({toFormatPrice(new BigNumber(item?.prices[0][0]),3,3)}-{toFormatPrice(new BigNumber(item?.prices[item?.prices.length-1][1]),3,3)})</>
                        }
                      </Flex>
                    </Flex>
                    }
                    
            
                  </StyleRow35>
                  <StyleRow25 style={{width:isDesktop?"10%":""}} isMobile={!isDesktop}>{item.fee/100}%</StyleRow25>
                  <StyleRow20 style={{width:isDesktop?"25%":""}} isMobile={!isDesktop}>{total(item.amounts)}</StyleRow20>
                  <StyleRow20 style={{width:isDesktop?"30%":""}}  isMobile={!isDesktop}>
                        <RemoveBtn deprecated={item?.deprecated} amount={total(item.amounts)} ids={item.ids} pairAddress={item.address} removedAmounts={item.amounts}/>
                        <Flex ml={2}/>
                        <TransferBtn amount={total(item.amounts)} ids={item.ids} pairAddress={item.address} removedAmounts={item.amounts}/>
                  </StyleRow20>
            </Flex>
           
            {status?<>
              {/* <Flex>
                <StyleDown/> 
                <Flex p={3} pl={0} 
                 justifyContent='flex-start' 
                 flexDirection={!isDesktop?'column':'row'} 
                 flex={1}>
                  <StyleTokenDetails flexDirection='column'>
                    <Text bold>Liquidity Pool</Text>
                    <Flex flex={1} mt={2} p={1} flexDirection='column'>
                      <Flex justifyContent='space-between'><Text>EBEN</Text><Text>10</Text></Flex>
                      <Flex justifyContent='space-between'><Text>$CATS</Text><Text>10</Text></Flex>
                    </Flex>
                  </StyleTokenDetails>

                  <StyleTokenDetails mt={!isDesktop?3:0} ml={!isDesktop?0:4} flexDirection='column'>
                    <Text bold>APR</Text>
                    <Flex flex={1} mt={2} p={1} flexDirection='column'>
                      <Flex justifyContent='space-between'><Text>1-Day APR</Text><Text>10%</Text></Flex>
                      <Flex justifyContent='space-between'><Text>7-Day APR</Text><Text>10%</Text></Flex>
                      <Flex justifyContent='space-between'><Text>1-Year APR</Text><Text>10%</Text></Flex>
                    </Flex>
                  </StyleTokenDetails>

       
                </Flex>
              </Flex> */}
              <LpDetails 
                tokenA={tokenFrom}
                tokenB={tokenTo}
                tokenAAmount={totalAmount(apy.leftStocks,tokenFrom?.decimals)}
                tokenBAmount={totalAmount(apy.gotMoneys,tokenTo?.decimals)}
                dayApy={apy.dayApy}
                weekApy={apy.weekDayApy}
                yearApy={apy.yearApy}
              />
              {childen(item)}
            </>
            :""}
    </>
  )
}



export default ListContent
