import { getBenswapGridexRouterAddress, getBenswapGridexRouterHelperAddress } from 'utils/addressHelpers';
import Web3 from 'web3';
import { ChainId } from '@pancakeswap-libs/sdk-v2'
import multicall from 'utils/multicall';
import { ethers } from 'ethers';

import BigNumber from 'bignumber.js'
import GridexLogic from '../../constants/abis/gridex-logic.json'
import GridexLogicOld from '../../constants/abis/gridex-logic-old.json'
import BenswapGridexRouter from '../../constants/abis/BenswapGridexRouter.json'
import BenswapGridexRouterHelper from '../../constants/abis/BenswapGridexRouterHelper.json'

import { getNodeUrl } from '../../connectors/index';

export const RatioBase = new BigNumber(10 ** 19)
export const PriceBase = new BigNumber(2 ** 68)
export const WBCH = '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04'
// export const NetWorkUrl = getNodeUrl()
// testNet
// export const WBCH = '0x17F4FCF5b6E0A95D4eE331c8529041896A073F9b' // testnet
// export const NetWorkUrl = "https://moeing.tech:9545"

function getWeb3() {
  return new Web3(Number(window.web3?.currentProvider?.chainId) === ChainId.MAINNET && window.web3.currentProvider || new Web3.providers.HttpProvider(getNodeUrl()));
}

let benswapGridexRouter
export function getBenswapGridexRouter() {
  if (benswapGridexRouter) {
    return benswapGridexRouter
  }

  const web3 = getWeb3();
  const contract = new web3.eth.Contract(BenswapGridexRouter as any, getBenswapGridexRouterAddress())
  benswapGridexRouter = {
    batchAddLiquidity: (
      account, sureFuc, bchAmount,
      tokenFrom,
      tokenTo,
      impl,
      midGridRange: number[],
      midGridIndex: number,
      maxStock,
      maxMoney,
      grids_addAmounts_ratios
    ) => {
      return contract.methods.batchAddLiquidity(
        tokenFrom,
        tokenTo,
        impl,
        midGridRange,
        midGridIndex,
        maxStock,
        maxMoney,
        grids_addAmounts_ratios
      ).send({
        gas: '',
        from: account,
        value: bchAmount
      })
        .on('transactionHash', (tx) => {
          sureFuc(tx)
        })
    }
  }
  return benswapGridexRouter
}

let benswapGridexRouterHelper
export function getBenswapGridexRouterHelper() {
  if (benswapGridexRouterHelper) {
    return benswapGridexRouterHelper
  }

  const web3 = getWeb3();
  const contract = new web3.eth.Contract(BenswapGridexRouterHelper as any, getBenswapGridexRouterHelperAddress())
  benswapGridexRouterHelper = {
    getLowGridAndHighGrid: (pairAddr) => {
      return contract.methods.getLowGridAndHighGrid(
        pairAddr
      ).call({ gas: 1000000000000 })
    },
    getBenswapGridexLogicImplInfos: (tokenFrom, tokenTo) => {
      return contract.methods.getBenswapGridexLogicImplInfos(
        tokenFrom,
        tokenTo
      ).call({ gas: 1000000000000 })
    },
    getBenswapGridexInfo: (stock, money, gridexType) => {
      return contract.methods.getBenswapGridexInfo(
        stock,
        money,
        gridexType
      ).call({ gas: 1000000000000 })
    },
    getBenswapGridexPairAddress: (stock, money, gridexType) => {
      return contract.methods.getBenswapGridexPairAddress(
        stock,
        money,
        gridexType,
      ).call({ gas: 1000000000000 })
    },
    getBenswapResultOfBatchAddLiquidity: (tokenFrom, tokenTo, gridxType: number, grids: number[], midGridSoldRatio, amountIns: number[]) => {
      return contract.methods.getBenswapResultOfBatchAddLiquidity(
        tokenFrom,
        tokenTo,
        gridxType,
        grids,
        midGridSoldRatio,
        amountIns
      ).call({ gas: 1000000000000 })
    }
  }
  return benswapGridexRouterHelper
}


export function getGridexLogic(address) {
  const result = {
    isContact: () => address !== "0x0000000000000000000000000000000000000000",
    getMidGrid: () => {
      const web3 = getWeb3();
      const contract = new web3.eth.Contract(GridexLogic as any, address)
      return contract.methods.midGrid().call()
    },
    getPools: (beginGrid, length) => {
      return multicall(GridexLogic, new Array(length).fill(0).map((_, i) => ({
        address,
        name: 'pools',
        params: [parseInt(beginGrid) + i]
      })))
    },
    setApprovalForAll: (account, operator) => {
      const web3 = getWeb3();
      const contract = new web3.eth.Contract(GridexLogic as any, address)
      return contract.methods.setApprovalForAll(
        account,
        operator,
      ).call()
    },
    isApprovedForAll: (account, operator) => {
      const web3 = getWeb3();
      const contract = new web3.eth.Contract(GridexLogic as any, address)
      return contract.methods.isApprovedForAll(
        account,
        operator,
      ).call()
    },
    transferBatch: (account, to, sureFuc, ids, values) => {
      const web3 = getWeb3();
      const contract = new web3.eth.Contract(GridexLogic as any, address)
      return contract.methods.safeBatchTransferFrom(
        account,
        to,
        ids,
        values,
        web3.utils.asciiToHex("")
      ).send({
        gas: '',
        from: account,
        value: 0
      })
        .on('transactionHash', (tx) => {
          sureFuc(tx)
        })
    },
    loadParams: () => {
      const web3 = getWeb3();
      const contract = new web3.eth.Contract(GridexLogic as any, address)
      return contract.methods.loadParams.call()
    },
    batchRemoveLiquidity: async (deprecated, account, sureFuc, grids: number[], sharesDeltas: number[]) => {
      if (!deprecated) {
        const web3 = getWeb3();
        const contract = new web3.eth.Contract(GridexLogic as any, address)
        const res = await contract.methods.batchRemoveLiquidity(
          grids,
          sharesDeltas
        ).send({
          gas: '',
          from: account,
          value: 0
        })
          .on('transactionHash', (tx) => {
            sureFuc(tx)
          })
      } else {
        const web3 = getWeb3();
        const contract = new web3.eth.Contract(GridexLogicOld as any, address)
        const res = await contract.methods.batchChangeShares(
          grids[0],
          sharesDeltas.map(v => ethers.BigNumber.from(v).mul(-1)),
          0,
          0
        ).send({
          gas: '',
          from: account,
          value: 0
        })
          .on('transactionHash', (tx) => {
            sureFuc(tx)
          })
      }
    }
  }

  return result
}

export function isBch(token) {
  return token === "0x0000000000000000000000000000000000000000" || token === "0x0000000000000000000000000000000000002711"
}

